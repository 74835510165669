import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ErrorLayout from '../layouts/ErrorLayout';

import Landing from 'components/pages/landing/Landing';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

const FalconRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
